// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"



// Highlighting for code blocks
// import "prismjs/themes/prism.css"⇒これがあると、オペレータ(<とか=とか)の色がおかしくなる。

// prismjs
// require("prismjs/themes/prism-solarizedlight.css")
require("prismjs/themes/prism-okaidia.css")
// require("prismjs/themes/prism.css")
// require("prismjs/themes/prism-dark.css")
