import { extendTheme } from "@chakra-ui/react"
const theme = {
  fonts: {
    // body: "'Bierstadt','Avenir','Segoe UI', 
    //'ヒラギノ角ゴシック', 'Hiragino Sans', 'メイリオ', Meiryo,  system-ui, sans-serif",
    body: "'ヒラギノ角ゴシック', 'Hiragino Sans', 'Segoe UI', 'メイリオ', system-ui, sans-serif",
    // heading: "'Bierstadt','Avenir','Segoe UI', 'ヒラギノ角ゴシック', 'Hiragino Sans', 'メイリオ', Meiryo,  system-ui, sans-serif",
    heading: "'ヒラギノ角ゴシック', 'Hiragino Sans', 'Segoe UI', 'メイリオ', system-ui, sans-serif",
    mono: "'Source Code Pro', 'BIZ UDGothic', 'BIZ UDゴシック', Menlo, monospace",
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        color: "gray.900",
      },
    },
  },
}

export default extendTheme(theme)
